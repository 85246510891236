<template>
	<div id="groupStudy">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">小组管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>

			</div>
			<div class="content" style="height: 78.6vh;" v-if="teacherIndex==1">
				<div class="title u-f-item u-f-jsb">
					<div>共{{ total }}条数据</div>
					<div class="u-f-item">
						<el-button size="small" @click="addUserShow = true">添加账号</el-button>
					</div>
				</div>
				<el-table
					:data="listsOperator"
					height="85%"
					ref="multipleTable"
					tooltip-effect="dark"
					style="width: 100%;border: 1px solid #EEEEEE;"
				>
					<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
					<el-table-column prop="class.grade.name" label="年级" align="center"></el-table-column>
					<el-table-column prop="class.name" label="班级" align="center"></el-table-column>
					<el-table-column prop="number" label="小组数" align="center"></el-table-column>
					<el-table-column prop="account" label="账号" align="center"></el-table-column>
					<el-table-column prop="createtime" label="创建时间" align="center"></el-table-column>
					<el-table-column label="操作" width="120" align="center">
						<template slot-scope="scope">
							<el-button @click="editscore(scope.row)" type="text" size="small">编辑</el-button>
							<span style="color: #E0E0E0;">|</span>
							<el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 10px 0;">
					<!-- <el-button type="danger" plain size="mini" @click="del">删除</el-button> -->
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<!-- 添加账号 -->
			<el-dialog title="添加账号" :visible.sync="addUserShow" width="600px" :before-close="CloseHandle">
				<div class="edit-box" style="padding:0 0 20px 40px ;max-height: 500px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择" style="width: 75%;" @change="gradeChanage">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							班级
						</div>
						<el-select v-model="class_id" placeholder="请选择" style="width: 75%;" @change="classChanage">
							<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							小组数
						</div>
						 <el-input-number :min="0" v-model="number" label="请输入小组数"></el-input-number>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							账号
						</div>
						<el-input v-model="account" placeholder="请输入账号" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							密码
						</div>
						<el-input v-model="password" placeholder="请输入密码" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeaddUser" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="addOperator">确 定</el-button>
				</span>
			</el-dialog>

			<el-dialog title="编辑账号" :visible.sync="editUserShow" width="600px" :before-close="CloseHandle">
				<div class="edit-box" style="padding:0 0 20px 40px ;max-height: 500px;">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择" style="width: 75%;" @change="gradeChanage">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							班级
						</div>
						<el-select v-model="class_id" placeholder="请选择" style="width: 75%;" @change="classChanage">
							<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							小组数
						</div>
						 <el-input-number v-model="number" :min="0" label="请输入小组数"></el-input-number>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="width: 80px;">
							<span>*</span>
							密码
						</div>
						<el-input v-model="password" placeholder="请输入密码" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeaddUser" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="editOperator">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			editUserShow:false,
			addUserShow:false,
			teacherMeun: [
				{ name: '小组评分',id:1},
				{ name: '小组配置',id:2}
			],
			teacherIndex: 1,
			grade_id: '',
			grade_name:'',
			class_id: '',
			class_name: '',
			gradeList:[],
			classList:[],
			total:0,
			page:1,
			limit:10,
			account:'',
			password:'',
			listsOperator:[],
			eidtId:'',
			number:0
		};
	},
	mounted() {
		this.getgradeList()
		this.getlistsOperator()
	},
	methods: {
		closeaddUser(){
			this.addUserShow = false;
			this.init()
		},
		twacherTap(index){
			if(index==0){
				this.$router.push('/groupStudy')
			}else{
				this.$router.push('/groupIndex')
			}
		},
		gradecn(){
			if(this.gradeId){
				this.getClassList()
				this.classId = '';
				this.page = 1;
			}
		},
		gradeChanage(){
			if(this.grade_id){
				for(let i in this.gradeList){
					if(this.grade_id == this.gradeList[i].id){
						this.grade_name = this.gradeList[i].name;
					}
				}
				this.getClassList()
				this.class_id = '';
			}
		},
		classChanage(){
			if(this.class_id){
				for(let i in this.classList){
					if(this.class_id == this.classList[i].id){
						this.class_name = this.classList[i].name;
					}
				}
			}
		},
		CloseHandle(done) {
			done();
			this.init();
		},
		// 获取年级列表
		getgradeList(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
				}
			})
		},
		// 获取班级列表
		getClassList(){
			this.$api.setting.getClassList({
				filter: JSON.stringify({'grade_id': this.grade_id||this.gradeId})
			}).then(res=>{
				this.classList = res.data.rows;
			})
		},
		init(){
			this.grade_id = '';
			this.class_id = '';
			this.classList = [];
			this.account = '';
			this.password = '';
			this.number = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getlistsOperator();
		},
		editscore(e){
			this.eidtId = e.id;
			this.grade_id = e.grade_id;
			this.grade_name = e.grade_name;
			this.getClassList();
			this.class_id = e.class_id;
			this.class_name = e.class_name;
			this.eidtId = e.id;
			this.number = e.number;
			this.editUserShow = true;
		},
		del(id){
			this.$confirm('确认要删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.$api.setting.teamdelUser({id:id}).then(res=>{
					if(res.data.code==1){
						this.$message.success('删除成功')
						this.page = 1;
						this.getlistsOperator()
					}else{
						this.$message.error(res.data.msg)
					}
				})
			}).catch(() => {});

		},
		closeedit(){
			this.editShow = false;
			this.init();
		},
		// 添加小组账号
		addOperator(){
			this.$api.setting.addOperator({
				grade_id:this.grade_id,
				class_id:this.class_id,
				grade_name:this.grade_name,
				class_name:this.class_name,
				account:this.account,
				password:this.password,
				number:this.number
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.getlistsOperator()
					this.init()
					this.addUserShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 获取小组账号列表
		getlistsOperator(){
			let data = {
				page:this.page,
				limit:this.limit
			}
			this.$api.setting.getlistsOperator(data).then(res=>{
				if(res.data.code==1){
					this.listsOperator = res.data.data;
					this.total = res.data.total;
				}
			})
		},
		editOperator(){
			this.$api.setting.editOperator({
				grade_id:this.grade_id,
				class_id:this.class_id,
				grade_name:this.grade_name,
				class_name:this.class_name,
				password:this.password,
				number:this.number,
				id:this.eidtId
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('修改成功');
					this.getlistsOperator()
					this.init()
					this.addUserShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		}
	}
};
</script>

<style lang="scss">
#groupStudy {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}

	.content {
		background-color: #ffffff;
		height: 79vh;
		margin-top: 20px;
		padding: 0 30px;
		.title {
			padding: 15px 0;
			.btn {
				background-color: #f3f3f3;
				font-size: 12px;
				padding: 5px 10px;
				color: #555555;
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	.pad {
		background-color: #e4f1ff;
		color: #2d8cf0;
		padding: 6px 20px;
		display: inline-block;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.edit-box {
			padding: 0 20px 0 20px;
			max-height: 60vh;
			overflow-y: auto;
			.input-box {
				margin-top: 35px;
				.input-lebal {
					width: 25%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
